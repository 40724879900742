var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"campaign-info"},[_c('TeacherPage'),_c('StudenPage'),(
        _vm.$route.matched[0].path.substring(1) === 'parent' && _vm.$route.query.student
      )?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('things-to-do')],1)],1):_vm._e(),(
        _vm.getDonationPageType.type === 'teacher' ||
        _vm.getDonationPageType.type === 'student'
      )?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('event-timeline')],1)],1):_vm._e(),(
    !_vm.getDonationPageType ||
    _vm.getDonationPageType.type === 'school' ||
    _vm.getDonationPageType.type === 'teacher' ||
    (_vm.getDonationPageType.type === 'student' &&
      _vm.$route.matched[0].path.substring(1) !== 'parent')
  )?_c('div',{staticClass:"org-section"},[_c('div',{staticClass:"campaign-message-wrapper"},[_c('div',{staticClass:"OrgInfo"},[_c('img',{staticClass:"org-logo",attrs:{"src":_vm.getTitle.logo,"alt":"school logo"}}),_c('span',{staticClass:"headingSchool"},[_vm._v(_vm._s(_vm.getTitle.title))])]),_c('campaign-message')],1)]):_vm._e(),(
        _vm.getDonationPageType.type === 'student' &&
        _vm.$route.matched[0].path.substring(1) !== 'parent'
      )?_c('v-row',{staticClass:"schoolProgress"},[_c('v-col',{attrs:{"cols":"12"}},[_c('school-progress-detail')],1)],1):_vm._e(),(
        _vm.$route.matched[0].path.substring(1) !== 'parent' &&
        (!_vm.getDonationPageType.type || _vm.getDonationPageType.type === 'school')
      )?_c('v-row',{staticClass:"timelineContainer"},[_c('v-col',{attrs:{"cols":"12"}},[_c('event-timeline')],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }