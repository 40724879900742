<template>
  <div v-if="getDonationPageType.type === 'teacher'" class="teacher-container">
    <div class="teacher-content">
      <!-- <h1>Teacher Profile</h1> -->
      <div v-if="getTeacherDetail.profile_pic" class="teacher-image">
        <img
          :src="getTeacherDetail.profile_pic"
          class="imgSTA"
          alt="Teacher Profile"
        />
      </div>
      <div v-else class="Profile">
        <img
          src="@/assets/publicDonationPage/teacher.png"
          class="imgST"
          alt="Teacher Profile"
        />
        <div class="details">
          <div class="Name">{{ getTeacherDetail.name }}</div>
          <div class="student_grade_teacher">
            (Grade {{ getTeacherDetail.grade }})
          </div>
        </div>
      </div>
      <div class="teacher-info">
        <div id="teacherMessage" v-html="getTeacherDetail.message"></div>
      </div>
    </div>
  </div>
</template>

<style scoped>
h1,
h2 {
  font-family: "Roboto Slab", serif;
  font-size: 26px;
  font-weight: bold;
  color: #2c1963;
  place-self: center;
}
p {
  font-family: "lato", serif;
}
.teacher-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px;
  background: #f9f9f9;
}

.teacher-content {
  max-width: 100vw;
  width: 100%;
  background: #ffffff;
  padding: 25px;
  border-radius: 12px;
  /* box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1); */
}

.teacher-image,
.Profile {
  text-align: center;
  margin-bottom: 20px;
}

.imgSTA {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  object-fit: cover;
  border: 4px solid #ff9800;
}

.imgST {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  object-fit: cover;
  border: 4px solid #ccc;
}

.details {
  margin-top: 10px;
}

.Name {
  font-size: 1.6rem;
  font-weight: bold;
  color: #2c1963;
}

.student_grade_teacher {
  font-size: 1.1rem;
  color: #666;
}

.teacher-info {
  font-size: 1.1rem;
  line-height: 1.6;
  color: #444;
  padding: 15px;
  border-top: 1px solid #eee;
  margin-top: 20px;
  text-align: justify;
  color: black;
}
</style>

<script>
import { mapGetters } from "vuex";
export default {
  name: "CampaignInfo",
  computed: {
    ...mapGetters({
      getCampaign: "publicDonationPage/getCampaign",
      userDetails: "publicDonationPage/getUserDetails",
    }),
    getDonationPageType() {
      return this.getCampaign.type;
    },
    getTeacherDetail() {
      if (this.getDonationPageType.type === "teacher") {
        const teacher = this.getCampaign.teacher_detail;
        return {
          name: teacher.display_name,
          grade: teacher.grade_title,
          message: teacher.teacher_special_message,
          profile_pic: teacher.profile_pic,
        };
      }
      return null;
    },
  },
};
</script>
