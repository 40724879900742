<template>
  <div v-if="getDonationPageType.type === 'student'" class="student-container">
    <v-card class="student-card">
      <!-- <h1>Student Profile</h1> -->

      <v-card-title class="student-header">
        <v-avatar class="student-avatar" size="100">
          <img
            :src="
              getStudentDetail.student_logo ||
              require('@/assets/publicDonationPage/student.png')
            "
            alt="Student Profile"
          />
        </v-avatar>
        <div class="student-header-info">
          <h2 class="student-name">{{ getStudentDetail.name }} </h2>
          <span class="teacher-grade">
            {{ getStudentDetail.teacher }} (Grade {{ getStudentDetail.grade }})
          </span>
        </div>
      </v-card-title>

      <!-- <v-card-subtitle class="org-info">
        <v-avatar size="60" class="org-logo">
          <img :src="getStudentDetail.org_logo" alt="Organization Logo" />
        </v-avatar>
        <span
          @click="viewPublicDonationPageHandler(getStudentDetail.school_id)"
          class="org-link"
        >
          {{ getStudentDetail.org_name }}
        </span>
        
      </v-card-subtitle> -->

      <v-card-text>
        <div class="student-message" v-html="getStudentDetail.message"></div>

        <div class="stats-container">
          <div class="win-icon" v-if="starCount >= 3">
              <img
                style="height:auto; width: 20rem"
                src="@/assets/thingsToDo/HWmedalwon.png"
                alt="Snow"
              />
            </div>
          <div class="d-flex stats-item">
            <span> You Have {{ starCount }} Shares</span>
            <v-icon
              class="stats-icon clickable"
              @click="addStar"
              :style="{ fontSize: 2 + 'px' }"
            >
              mdi-star
            </v-icon>
            <div class="d-flex stars-display">
              <v-icon
                v-for="(star, index) in starCount"
                :key="index"
                class="stats-icon"
                :style="{ fontSize: baseSize + index * sizeIncrement + 'px' }"
              >
                mdi-star
              </v-icon>
            </div>
          </div>

          <div class="d-flex stats-item">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <span v-bind="attrs" v-on="on">
                  {{ shareMessage }}
                </span>
              </template>
            </v-tooltip>
            <span v-if="starCount < 3"></span>
            <!-- <div class="stats-icon" v-else>
              <img
                style="width: 100%"
                src="@/assets/thingsToDo/HWmedalwon.png"
                alt="Snow"
              />
            </div> -->
          </div>
           
        </div>
      </v-card-text>

      <v-card-actions v-if="$route.matched[0].path.substring(1) === 'parent'">
        <v-btn
          v-if="$route.matched[0].path.substring(1) === 'parent'"
          color="primary"
          @click="editStudent"
        >
          Edit Profile
        </v-btn>
        <!-- <v-btn
          color="green"
          @click="viewPublicStudentDonationPageHandler(getStudentDetail)"
        >
          View Public Page
        </v-btn> -->
      </v-card-actions>
    </v-card>
  </div>
</template>

<style scoped>
/* Main Container */
h1,
h2 {
  font-family: "Roboto Slab", serif;
  font-size: 26px;
  font-weight: bold;
  color: #2c1963;
}
p {
  font-family: "lato", serif;
}
.student-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: full;
  color: black;
}
.v-sheet.v-card:not(.v-sheet--outlined) {
    box-shadow: none;
}
/* Card Styling */
.student-card {
  max-width: 100%;
  height: auto;
  background: #ffffff;
  border-radius: 12px;
  /* box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1); */
  overflow: hidden;
  text-align: justify;
  margin: 10px;
}
.student-image {
  height: 2vh;
}
/* Header with Student Avatar & Name */
.student-header {
  height: 100%;
  display: flex;
  align-items: center;
  gap: 15px;
}

.student-avatar img {
  border-radius: 20%;
  width: auto;
  /* height: auto; */
  object-fit: contain;
}
.v-avatar {
  display: inline-flex;
  justify-content: center;
  line-height: normal;
  position: relative;
  text-align: justify;
  vertical-align: middle;
  /* overflow: hidden; */
}

/* Organization Information */
.org-info {
  display: flex;
  align-items: center;
  gap: 10px;
  text-align: justify;
  justify-content: center;
  padding: 10px;
  margin: 5px;
  color: black !important;
}

.org-logo img {
  border-radius: 50%;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

/* Organization Name Link */
.org-link {
  cursor: pointer;
  font-size: 1.5rem;
  font-weight: bold;
  font-family: Roboto-slab;
  font-weight: 700;
  transition: 0.3s ease;
}

.org-link:hover {
  text-decoration: underline;
  color: #0056b3;
}

/* Student Message */
.student-message {
  text-align: justify;
  font-size: 1rem;
  font-weight: 500;
  color: black;
  padding: 10px 0;
}
.stars-display {
  width: auto;
}
/* Stats Section */
.stats-container {
  display: grid;
  grid-template-columns: 1fr 1fr; /* two equal columns */
  grid-template-rows: 2fr 1fr;
  gap: 10px;
  margin: 10px 0;
  overflow: auto;
  color: #2c1963;
  align-items: center;
}

.win-icon {
  grid-column: 1 / -1;
  justify-self: center;
  max-width: 40%;
  margin-bottom: 10px;
}

.win-icon img {
  width: 100%;
  height: auto;
  display: block;
}

.stats-item {
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 1rem;
  font-weight: bold;
  padding: 8px;
  background-color: antiquewhite;
  border-radius: 8px;
  min-height: 5rem;
  min-width: 25vw;
  overflow: auto;
}

.stats-icon {
  font-size: 2.5rem;
  color: #ff9800;
}

/* Buttons */
.v-card-actions {
  display: flex;
  justify-content: center;
  gap: 10px;
  padding-bottom: 10px;
}

/* Responsive Design */
@media (max-width: 600px) {
  .student-card {
    max-width: 100%;
    place-self: center;
  }
  .stats-container {
    display: flex;
    gap: 10px;
    margin: 10px 0;
    flex-wrap: wrap;
  }
  stats-icon {
    font-size: 1.5rem;
    color: #ff9800;
  }
  .stats-container {
    grid-template-columns: 1fr;
  }
}
</style>
<script>
import { mapGetters, mapActions } from "vuex";
import Axios from "@/api/BaseAxios";
import { API_ADMIN_GET_SCHOOL_DETAIL } from "@/constants/APIUrls";
import _ from "lodash";
import Helper from "@/helper";
export default {
  name: "CampaignInfo",
  components: {},
  data() {
    return {
      schoolDetail: {},
      showeditmodal: false,
      starCount: 0, // Keeps track of how many stars to show
      baseSize: 30, // The base font size (in pixels) for the first star
      sizeIncrement: 14,
    };
  },
  computed: {
    ...mapGetters({
      getCampaign: "publicDonationPage/getCampaign",
      userDetails: "publicDonationPage/getUserDetails",
    }),
    shareMessage() {
      return this.starCount < 3
        ? "In order to complete the challenge and win the champion medallion, you need to share with at least 3 contacts and earn all of the badges."
        : "Congratulations! you have won a Light-up Champion Medallion!";
    },
    getCampaignDetail() {
      return this.getCampaign.campaign_detail;
    },
    studentDetail() {
      return this.getCampaign.student_detail;
    },
    getDonationPageType() {
      return this.getCampaign.type;
    },
    isLoggedIn() {
      return _.isEmpty(this.userDetails) ? false : this.userDetails;
    },
    getTeacherDetail() {
      if (this.getDonationPageType.type === "teacher") {
        let teacher_name = this.getCampaign.teacher_detail.display_name;
        let grade = this.getCampaign.teacher_detail.grade_title;
        let teacher_special_message =
          this.getCampaign.teacher_detail.teacher_special_message;
        let pic = this.getCampaign.teacher_detail.profile_pic;

        return {
          name: teacher_name,
          grade: grade,
          message: teacher_special_message,
          profile_pic: pic,
        };
      } else {
        return null;
      }
    },
    getStudentDetail() {
      if (this.getDonationPageType.type === "student") {
        let student_name = this.getCampaign.student_detail.student_first_name;
        let last_name = this.getCampaign.student_detail.student_last_name;
        let student_name_full = `${student_name  || ''} ${last_name || ''}`.trim();
        localStorage.setItem("studentfirstname", student_name);
        console.log("et student_name", this.getCampaign.student_detail);
        let special_message =
          this.getCampaign.student_detail.student_special_message;
        let organization_name = this.getCampaign.student_detail.school_name;
        let organization_logo = this.getCampaign.student_detail.school_logo;
        let teacher = this.getCampaign.student_detail.grade_teacher;
        let grade = this.getCampaign.student_detail.grade_title;
        let school = this.getCampaign.student_detail.school_id;
        let campaign = this.getCampaign.campaign_detail.id;
        let days_left = this.getCampaign.campaign_detail.remaining_day;
        let pic = this.getCampaign.student_detail.student_logo;
        let distinctshare = this.getCampaign.student_detail.distinct_shares;
        let id = this.getCampaign.student_detail.id;
        let medaleligibility =
          this.getCampaign.student_detail.medal_eligibility;
        console.log("et pic", pic);
        localStorage.setItem("studentProfilepic", pic);
        return {
          name: student_name_full,
          org_name: organization_name,
          org_logo: organization_logo,
          message: special_message,
          teacher: teacher,
          id: id,
          grade: grade,
          school_id: school,
          campaign_id: campaign,
          days: days_left,
          student_logo: pic,
          distinct_shares: distinctshare,
          medal_eligibility: medaleligibility,
        };
      } else {
        return {
          name: null,
          org_name: null,
          org_logo: null,
          message: null,
          teacher: null,
          grade: null,
          school_id: null,
          campaign_id: null,
          days: null,
          student_logo: null,
        };
      }
    },
    getTitle() {
      let title = "";
      let logo = "";
      let specialMessage = "";
      if (!this.getDonationPageType) {
        title = this.getCampaignDetail.organization_name;
        logo = this.getCampaignDetail.organization_logo;
        specialMessage = this.getCampaignDetail.special_message;
      } else if (this.getDonationPageType.type === "school") {
        title = this.getCampaign.school_detail.school_name;
        logo = this.getCampaign.school_detail.logo_url;
        specialMessage = this.getCampaignDetail.special_message;
      } else if (this.getDonationPageType.type === "teacher") {
        title = this.getCampaign.teacher_detail.campaign_school;
        logo = this.getCampaign.teacher_detail.campaign_school_logo;
        specialMessage = this.getCampaignDetail.special_message;
      } else if (this.getDonationPageType.type === "student") {
        title = this.getCampaign.student_detail.school_name;
        logo = this.getCampaign.student_detail.school_logo;
        specialMessage = this.getCampaignDetail.special_message;
      }
      return { title: title, logo: logo, message: specialMessage };
    },
    schoolFundRaisedPercentage() {
      return Math.round(
        (this.schoolDetail.total_fund_raised / this.schoolDetail.school_goal) *
          100
      );
    },
    getDetailMessage() {
      return this.getCampaignDetail.message;
    },
  },
  methods: {
    ...mapActions({
      toggleCreateStudentModal: "publicDonationPage/toggleCreateStudentModal",
    }),
    addStar() {
      // Increase the star count by one, which adds a new star.
      this.starCount++;
    },
    getSchoolDetail() {
      const successHandler = (res) => {
        this.schoolDetail = res.data.school_detail;
        //   localStorage.setItem("first_name",student_name)
        console.log("studentfirstname*****", res.data.school_detail);
      };
      const failureHandler = (res) => {
        console.log(res);
      };
      let formData = {};
      formData["school_id"] = this.getStudentDetail.school_id;
      formData["campaign_id"] = this.getStudentDetail.campaign_id;
      Axios.request_GET(
        API_ADMIN_GET_SCHOOL_DETAIL,
        formData,
        {},
        successHandler,
        failureHandler,
        false,
        false
      );
    },
    viewPublicDonationPageHandler(school) {
      const queryParam = "school=" + school;
      Helper.openPublicDonationPage("", queryParam);
    },
    viewPublicStudentDonationPageHandler(item) {
      // console.log(item, "here is item");
      const { id } = item;
      const queryParam = "student=" + id;
      const host = window.location.host;
      const protocol = window.location.protocol;
      const href = protocol + "//" + host + "/?" + queryParam;
      window.open(href);
    },
    editStudent() {
      this.toggleCreateStudentModal({
        show: true,
        actionType: "edit",
        id: this.$route.query.student,
      });
    },
  },
  mounted() {
    this.getSchoolDetail();
    this.starCount = this.getStudentDetail.distinct_shares;
    // console.log(this.getCampaign, "student");
  },
};
</script>
