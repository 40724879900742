<template>
  <div class="campaign-info">
    <!-- Teacher Page -->
    <TeacherPage />
    <!-- Student Page -->
    <StudenPage />

    <!-- Things-to-do & Event Timeline -->
    <v-row
      v-if="
        $route.matched[0].path.substring(1) === 'parent' && $route.query.student
      "
    >
      <v-col cols="12">
        <things-to-do />
      </v-col>
    </v-row>
    <v-row
      v-if="
        getDonationPageType.type === 'teacher' ||
        getDonationPageType.type === 'student'
      "
    >
      <v-col cols="12">
        <event-timeline />
      </v-col>
    </v-row>

    <!-- Organization Title & Campaign Message -->
<div
  v-if="
    !getDonationPageType ||
    getDonationPageType.type === 'school' ||
    getDonationPageType.type === 'teacher' ||
    (getDonationPageType.type === 'student' &&
      $route.matched[0].path.substring(1) !== 'parent')
  "
  class="org-section"
>

  <div class="campaign-message-wrapper">
    <div class="OrgInfo">
    <img :src="getTitle.logo" alt="school logo" class="org-logo" />
    <span class="headingSchool">{{ getTitle.title }}</span>
  </div>  
    <campaign-message />
  </div>
</div>

    <!-- <v-row>
      <v-col cols="12">
        <campaign-message></campaign-message>
      </v-col>
    </v-row> -->

    <!-- School Progress Detail -->
    <v-row
      v-if="
        getDonationPageType.type === 'student' &&
        $route.matched[0].path.substring(1) !== 'parent'
      "
      class="schoolProgress"
    >
      <v-col cols="12">
        <school-progress-detail></school-progress-detail>
      </v-col>
    </v-row>

    <!-- Event Timeline for School -->
    <v-row
      class="timelineContainer"
      v-if="
        $route.matched[0].path.substring(1) !== 'parent' &&
        (!getDonationPageType.type || getDonationPageType.type === 'school')
      "
    >
      <v-col cols="12">
        <event-timeline></event-timeline>
      </v-col>
    </v-row>
  </div>
</template>

<style scoped>


.campaign-info{
  background-color: transparent;
}
.schoolProgress {
  font-family: Roboto slab !important;
  place-content: center;
  padding: 15px;
}

.timelineContainer {
  width: full;
  padding: 10px;
  justify-items: center;
}
.teacher-container {
  width: fit;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-areas:
    "Profile teacher-info"
    "Profile teacher-info";
}
.teacher-info {
  grid-area: teacher-info;
  display: flex;
  font-size: large;
}
/* Profile Image */
.Profile {
  grid-area: Profile;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}
.campaign-message-wrapper{
  padding: 16px;
}

.Profile img,
.StudentProfile img {
  max-width: 8rem;
  width: 100%;
  height: auto;
  border-radius: 10%;
  place-self: left;
  padding: 1%;
  margin: 10px;
  object-fit: contain;
}
.details {
  display: grid;
  font-size: 5vh;
}

.dashed-line {
  width: 2px;
  height: 5px;
  color: #2c1963;
}

/* Headings and Text */
.heading {
  font-size: 2.5vw;
  font-family: Roboto Slab;
  font-weight: 600;
  color: #2c1963;
  display: flex;
}

.paragraphMessage {
  font-size: 0.5vw;
}
.Name {
  font-size: 3.5vh;
  font-family: Roboto Slab;
  color: #2c1963;
  font-weight: 600;
}
.responsive-btn:hover {
  scale: 105%;
  transition: all;
}
.OrgInfo {
  margin-top: 10px;
  gap: 10px;
  padding-inline: 16px;
  display: flex;
  align-items: center;
  flex-wrap: nowrap; /* Default: keep in one line */
  justify-content: start;
  text-align: justify;

}.org-section{
  width: auto;
}

.org-logo {
  width: 100px; /* Fixed width for larger screens */
  height: auto;
  object-fit: contain;
}

/* Heading style for organization title */
.headingSchool {
  font-size: 2rem;
  font-weight: 700;
  font-family: "Roboto Slab", serif;
  /* Optional: add some margin if desired */
}

/* Responsive adjustments for small screens */
@media only screen and (max-width: 600px) {
  .OrgInfo {
    flex-direction: column; /* Stack logo and title vertically */
    align-items: center; /* Center both items */
    text-align: justify;
  }
  .org-logo {
    width: 80px; /* Reduce logo size on small screens */
  }
  .headingSchool {
    font-size: 1.5rem; /* Smaller font for the title */
  }
}

/* Media Queries for smaller devices */
@media only screen and (max-width: 900px) {
  .heading {
    font-size: 5vw;
  }

  .Name {
    font-size: 6vw;
  }
  .shared-info {
    align-self: center;
    font-size: 1rem;
  }
  .chmpionclass {
    font-size: 1rem;
  }
  .org-logo {
    max-width: 100%; /* Adjusted to fit better */
    object-fit: contain;
  }
}
</style>

<script>
import { mapGetters, mapActions } from "vuex";
import Axios from "@/api/BaseAxios";
import { API_ADMIN_GET_SCHOOL_DETAIL } from "@/constants/APIUrls";
import _ from "lodash";
import Helper from "@/helper";
import StudenPage from "./StudentPage.vue";
import TeacherPage from "./TeacherPage.vue";
export default {
  name: "CampaignInfo",
  components: {
    StudenPage,
    TeacherPage,
    EventTimeline: () => import("../DonationInfoCard/EventTimeline"),
    CampaignMessage: () =>
      import(
        "@/components/PublicDonationPages/CampaignMessage/CampaignMessage"
      ),
    ThingsToDo: () => import("../ThingsToDo/ThingsToDo"),
    // IntroVideoPdp: () =>
    //   import("@/components/PublicDonationPages/IntroVideoPdp/IntroVideoPdp"),
    SchoolProgressDetail: () =>
      import("../../SchoolProgressDetail/SchoolProgressDetail"),
  },
  data() {
    return {
      schoolDetail: {},
      showeditmodal: false,
    };
  },
  computed: {
    ...mapGetters({
      getCampaign: "publicDonationPage/getCampaign",
      userDetails: "publicDonationPage/getUserDetails",
    }),
    getCampaignDetail() {
      return this.getCampaign.campaign_detail;
    },
    studentDetail() {
      return this.getCampaign.student_detail;
    },
    getDonationPageType() {
      return this.getCampaign.type;
    },
    isLoggedIn() {
      return _.isEmpty(this.userDetails) ? false : this.userDetails;
    },
    getTeacherDetail() {
      if (this.getDonationPageType.type === "teacher") {
        let teacher_name = this.getCampaign.teacher_detail.display_name;
        let grade = this.getCampaign.teacher_detail.grade_title;
        let teacher_special_message =
          this.getCampaign.teacher_detail.teacher_special_message;
        let pic = this.getCampaign.teacher_detail.profile_pic;

        return {
          name: teacher_name,
          grade: grade,
          message: teacher_special_message,
          profile_pic: pic,
        };
      } else {
        return null;
      }
    },
    getStudentDetail() {
      if (this.getDonationPageType.type === "student") {
        let student_name = this.getCampaign.student_detail.student_first_name;
        localStorage.setItem("studentfirstname", student_name);
        // console.log("et student_name", student_name);
        let special_message =
          this.getCampaign.student_detail.student_special_message;
        let organization_name = this.getCampaign.student_detail.school_name;
        let organization_logo = this.getCampaign.student_detail.school_logo;
        let teacher = this.getCampaign.student_detail.grade_teacher;
        let grade = this.getCampaign.student_detail.grade_title;
        let school = this.getCampaign.student_detail.school_id;
        let campaign = this.getCampaign.campaign_detail.id;
        let days_left = this.getCampaign.campaign_detail.remaining_day;
        let pic = this.getCampaign.student_detail.student_logo;
        let distinctshare = this.getCampaign.student_detail.distinct_shares;
        let medaleligibility =
          this.getCampaign.student_detail.medal_eligibility;
        // console.log("et pic", pic);
        localStorage.setItem("studentProfilepic", pic);
        return {
          name: student_name,
          org_name: organization_name,
          org_logo: organization_logo,
          message: special_message,
          teacher: teacher,
          grade: grade,
          school_id: school,
          campaign_id: campaign,
          days: days_left,
          student_logo: pic,
          distinct_shares: distinctshare,
          medal_eligibility: medaleligibility,
        };
      } else {
        return {
          name: null,
          org_name: null,
          org_logo: null,
          message: null,
          teacher: null,
          grade: null,
          school_id: null,
          campaign_id: null,
          days: null,
          student_logo: null,
        };
      }
    },
    getTitle() {
      let title = "";
      let logo = "";
      let specialMessage = "";
      if (!this.getDonationPageType) {
        title = this.getCampaignDetail.organization_name;
        logo = this.getCampaignDetail.organization_logo;
        specialMessage = this.getCampaignDetail.special_message;
      } else if (this.getDonationPageType.type === "school") {
        title = this.getCampaign.school_detail.school_name;
        logo = this.getCampaign.school_detail.logo_url;
        specialMessage = this.getCampaignDetail.special_message;
      } else if (this.getDonationPageType.type === "teacher") {
        title = this.getCampaign.teacher_detail.campaign_school;
        logo = this.getCampaign.teacher_detail.campaign_school_logo;
        specialMessage = this.getCampaignDetail.special_message;
      } else if (this.getDonationPageType.type === "student") {
        title = this.getCampaign.student_detail.school_name;
        logo = this.getCampaign.student_detail.school_logo;
        specialMessage = this.getCampaignDetail.special_message;
      }
      return { title: title, logo: logo, message: specialMessage };
    },
    schoolFundRaisedPercentage() {
      return Math.round(
        (this.schoolDetail.total_fund_raised / this.schoolDetail.school_goal) *
          100
      );
    },
    getDetailMessage() {
      return this.getCampaignDetail.teacher_incentive;
    },
  },
  methods: {
    ...mapActions({
      toggleCreateStudentModal: "publicDonationPage/toggleCreateStudentModal",
    }),

    getSchoolDetail() {
      const successHandler = (res) => {
        this.schoolDetail = res.data.school_detail;
        //   localStorage.setItem("first_name",student_name)
        // console.log("studentfirstname*****", res.data.school_detail);
      };
      const failureHandler = (res) => {
        console.log(res);
      };
      let formData = {};
      formData["school_id"] = this.getStudentDetail.school_id;
      formData["campaign_id"] = this.getStudentDetail.campaign_id;
      Axios.request_GET(
        API_ADMIN_GET_SCHOOL_DETAIL,
        formData,
        {},
        successHandler,
        failureHandler,
        false,
        false
      );
    },
    viewPublicDonationPageHandler(school) {
      const queryParam = "school=" + school;
      Helper.openPublicDonationPage("", queryParam);
    },
    viewPublicStudentDonationPageHandler(item) {
      const { id } = item;
      const queryParam = "student=" + id;
      const host = window.location.host;
      const protocol = window.location.protocol;
      const href = protocol + "//" + host + "/?" + queryParam;
      window.open(href);
    },
    editStudent() {
      this.toggleCreateStudentModal({
        show: true,
        actionType: "edit",
        id: this.$route.query.student,
      });
    },
  },
  mounted() {
    this.getSchoolDetail();
    // console.log(this.getCampaign, "student");
  },
};
</script>
